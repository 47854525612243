import { useDelayedQuery } from "./delay";
import { isOngoing, useStatuses } from "./status";

export type Game = {
    home: string;
    away: string;
    streamId: string;
};

export const useStreams = () => {
    const query = useDelayedQuery<Game[]>(
        "streams",
        async (previousGames = []) => {
            const data = await fetch("https://freelivestreamhd.com");
            const html = await data.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const eventTable = doc.getElementById("eventTable")!;
            const tableBody = eventTable.getElementsByTagName("tbody")[0];
            const rows = tableBody.getElementsByTagName("tr");

            const games: Game[] = [];
            for (const row of rows) {
                if (row.classList.contains("date-row")) {
                    continue;
                }
                const [_, gameCell, ...buttonCells] = Array.from(
                    row.getElementsByTagName("td"),
                );
                const gameName = gameCell.childNodes[0].textContent!;
                const [home, away] = gameName.split(" - ").map((t) => t.trim());
                const streamCell = buttonCells.find(
                    (c) => c.getElementsByTagName("button")[0],
                );
                const streamId = streamCell
                    ?.getElementsByTagName("button")[0]
                    .innerHTML?.trim();

                if (!streamId) {
                    continue;
                }

                games.push({
                    home,
                    away,
                    streamId,
                });
            }

            for (const previousGame of previousGames) {
                if (!games.find((g) => g.streamId === previousGame.streamId)) {
                    games.push(previousGame);
                }
            }

            return games;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return query;
};

export const useStreamingGames = () => {
    const { data: statuses } = useStatuses();
    const { data: streams } = useStreams();

    if (!statuses || !streams) {
        return [];
    }

    return statuses.filter(isOngoing).flatMap((status) => {
        const stream = streams?.find(
            (stream) =>
                stream.home === status.home && stream.away === status.away,
        );
        if (!stream && status.status === "FINISHED") {
            return [];
        }
        return [
            {
                game: stream,
                status,
            },
        ];
    });
};
