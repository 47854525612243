import { effect, signal } from "@preact/signals";

export const persistentSignal = <T>(key: string, initialValue: T) => {
    const localValue = localStorage.getItem(key);
    const value = localValue ? JSON.parse(localValue) : initialValue;
    const s = signal<T>(value);

    effect(() => {
        localStorage.setItem(key, JSON.stringify(s.value));
    });

    return s;
};
