import { render } from "preact";
import { SWRConfig } from "swr";
import App from "./App.tsx";
import "./index.css";

render(
    <SWRConfig>
        <App />
    </SWRConfig>,
    document.getElementById("root")!,
);
