import clsx from "clsx";
import { FunctionComponent as FC } from "preact";
import useAdTimeout from "../data/ad";
import { useGamesStore } from "../data/games";
import { mapPeriod } from "../data/status";
import { CalculatedGame, useActive, useBoost } from "../data/switcher";
import NumberInput from "./NumberInput";
import styles from "./ScoreBoard.module.css";

type ScoreBoardProps = {
    game: CalculatedGame;
};

const ScoreBoard: FC<ScoreBoardProps> = ({
    game: { game, points, status, homePlayers, awayPlayers },
}) => {
    const [[homeBoost, awayBoost], setBoost] = useBoost(status.eventId);
    const adTimeout = useAdTimeout();
    const { playingGames, play, stop, setActive } = useGamesStore();
    const activeGame = useActive();
    const isPlaying = game?.streamId
        ? playingGames.includes(game.streamId)
        : false;

    return (
        <div
            className={clsx(styles.scoreboardGame, {
                [styles.isPlaying]: isPlaying,
                [styles.isActive]: game?.streamId === activeGame,
            })}
            onClick={() => {
                if (game?.streamId === activeGame) {
                    setActive(null);
                } else if (isPlaying) {
                    setActive(game?.streamId ?? null);
                } else if (game) {
                    play(game.streamId);
                }
            }}
            title={!game ? "There is no stream for this game." : ""}
        >
            <input
                type="checkbox"
                checked={isPlaying}
                onClick={(event) => {
                    event.stopPropagation();
                    if (isPlaying) {
                        stop(game!.streamId);
                    } else if (game) {
                        play(game.streamId);
                    }
                }}
                disabled={!game}
                title={!game ? "There is no stream for this game." : ""}
            />
            <img
                className={clsx(styles.scoreboardImg, {
                    [styles.onField]: status.ball === "away",
                    [styles.inRedZone]: status.redzone,
                })}
                src={status.awayLogo}
            />
            <div className={styles.scoreboardScore}>{status.awayScore}</div>
            <div className={styles.scoreboardScore}>{status.homeScore}</div>
            <img
                className={clsx(styles.scoreboardImg, {
                    [styles.onField]: status.ball === "home",
                    [styles.inRedZone]: status.redzone,
                })}
                src={status.homeLogo}
            />
            <div>
                {mapPeriod(status.period)} {status.clock}
            </div>
            <div className={styles.scoreboardPoints}>
                {status.status === "FINISHED" ? (
                    <strong>FINAL</strong>
                ) : status.status === "HALF_TIME" ? (
                    <strong>HALF</strong>
                ) : status.lastPlayTimestamp < adTimeout ? (
                    <strong>AD</strong>
                ) : (
                    <div
                        title={points.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })}
                    >
                        <span
                            className={clsx(styles.scoreboardPlayerCount, {
                                [styles.onField]: status.ball === "away",
                                [styles.inRedZone]: status.redzone,
                            })}
                        >
                            {status.awayShort}{" "}
                            <NumberInput
                                value={awayPlayers + awayBoost}
                                onChange={(v) =>
                                    setBoost([homeBoost, v - awayPlayers])
                                }
                            />{" "}
                        </span>
                        <span
                            className={clsx(styles.scoreboardPlayerCount, {
                                [styles.onField]: status.ball === "home",
                                [styles.inRedZone]: status.redzone,
                            })}
                        >
                            {status.homeShort}{" "}
                            <NumberInput
                                value={homePlayers + homeBoost}
                                onChange={(v) =>
                                    setBoost([v - homePlayers, awayBoost])
                                }
                            />{" "}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ScoreBoard;
