import { FunctionComponent as FC } from "preact";
import { useGamesStore } from "../data/games";
import { usePointCalculator } from "../data/switcher";
import ScoreBoard from "./ScoreBoard";
import styles from "./FantasyScoreBoard.module.css";

const FantasyScoreBoard: FC = () => {
    const { isAuto, setAuto } = useGamesStore();
    const gamesWithPoints = usePointCalculator();

    return (
        <div className={styles.fantasyScoreboard}>
            <div className={styles.fantasyScoreboardTitle}>
                <h2>Current games</h2>

                <label title="Switch between games automatically">
                    Auto
                    <input
                        type="checkbox"
                        checked={isAuto}
                        onClick={() => setAuto(!isAuto)}
                    />
                </label>
            </div>
            <div className={styles.fantasyScoreboardGames}>
                {gamesWithPoints && gamesWithPoints?.length > 0 ? (
                    gamesWithPoints.map((gameWithPoints) => (
                        <ScoreBoard game={gameWithPoints} />
                    ))
                ) : (
                    <div>There are no games right now, come back later!</div>
                )}
            </div>
        </div>
    );
};

export default FantasyScoreBoard;
