import { persistentSignal } from "./persistent";

type GamesStore = {
    playingGames: string[];
    activeGame: string | null;
    isAuto: boolean;
};

const gamesStore = persistentSignal<GamesStore>("GAMES_STORE", {
    playingGames: [],
    activeGame: null,
    isAuto: false,
});

const play = (id: string) => {
    gamesStore.value = {
        ...gamesStore.value,
        activeGame: gamesStore.value.activeGame ?? id,
        playingGames: [
            ...gamesStore.value.playingGames.filter((g) => g !== id),
            id,
        ],
    };
};
const stop = (id: string) => {
    gamesStore.value = {
        ...gamesStore.value,
        activeGame:
            gamesStore.value.activeGame === id
                ? null
                : gamesStore.value.activeGame,
        playingGames: [
            ...gamesStore.value.playingGames.filter((g) => g !== id),
        ],
    };
};
const setActive = (activeGame: string | null) => {
    gamesStore.value = { ...gamesStore.value, activeGame, isAuto: false };
};
const setAuto = (isAuto: boolean) => {
    gamesStore.value = { ...gamesStore.value, isAuto };
};

export const useGamesStore = () => {
    return {
        activeGame: gamesStore.value.activeGame,
        isAuto: gamesStore.value.isAuto,
        playingGames: gamesStore.value.playingGames,
        play,
        stop,
        setActive,
        setAuto,
    };
};
