import { ChevronDown, ChevronUp } from "lucide-preact";
import { FunctionComponent } from "preact";
import styles from "./NumberInput.module.css";

type NumberInputProps = {
    value: number;
    onChange: (v: number) => void;
};

const NumberInput: FunctionComponent<NumberInputProps> = ({
    value,
    onChange,
}) => {
    return (
        <span className={styles.numberInput}>
            <span>{value}</span>
            <span className={styles.numberInputButtons}>
                <button
                    type="button"
                    className={styles.numberInputButton}
                    onClick={(event) => {
                        event.stopPropagation();
                        onChange(value + 1);
                    }}
                >
                    <ChevronUp size={12} />
                </button>
                <button
                    type="button"
                    className={styles.numberInputButton}
                    onClick={(event) => {
                        event.stopPropagation();
                        onChange(value - 1);
                    }}
                >
                    <ChevronDown size={12} />
                </button>
            </span>
        </span>
    );
};

export default NumberInput;
