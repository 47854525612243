import useSWR, { SWRConfiguration, useSWRConfig } from "swr";
import { persistentSignal } from "./persistent";

const sleep = (s: number) =>
    new Promise((resolve) => setTimeout(resolve, s * 1000));

const delayStore = persistentSignal<number>("DELAY_STORE", 10);
const setDelay = (s: number) => {
    delayStore.value = s;
};

export const useDelay = () => {
    return { delay: delayStore.value, setDelay };
};

export const useDelayedQuery = <T>(
    key: string,
    action: (t: T | undefined) => Promise<T>,
    options: SWRConfiguration<T>,
) => {
    const { cache } = useSWRConfig()
    const query = useSWR<T>(key, {
        fetcher: async () => {
            const previousData = cache.get(key);
            const data = await action(previousData?.data);
            if (previousData?.data) {
                await sleep(delayStore.value);
            }
            return data;
        },
        ...options,
    });
    return query;
};
