import clsx from "clsx";
import { FantasyTeamWithChanges } from "../data/fantasy";
import { useStatuses } from "../data/status";
import FantasyPlayerComponent from "./FantasyPlayer";
import styles from "./FantasyMatchupTeam.module.css";

type FantasyMatchupTeamProps = {
    team: FantasyTeamWithChanges;
    isOpen: boolean;
    isSelected: boolean;
    onSelect: () => void;
    onOpen: () => void;
};

function FantasyMatchupTeam({
    team,
    isOpen,
    isSelected,
    onSelect,
    onOpen,
}: FantasyMatchupTeamProps) {
    const statuses = useStatuses();
    const gameStatuses = team.players.map((player) => {
        const status = statuses.data?.find(
            (s) => player.team === s.homeShort || player.team === s.awayShort,
        );
        return status?.status;
    });
    const isScheduled = gameStatuses.filter((s) => s === "SCHEDULED").length;
    const isPlaying = gameStatuses.filter(
        (s) => s === "HALF_TIME" || s == "ONGOING",
    ).length;
    const isFinished = gameStatuses.filter((s) => s === "FINISHED").length;
    const total = isScheduled + isPlaying + isFinished;

    return (
        <div
            className={clsx(styles.fantasyMatchupTeam, {
                [styles.isChanged]: !isOpen && team.changed,
            })}
            title={team.name}
        >
            <div
                className={styles.fantasyMatchupTeamHeader}
                style={{
                    borderImage:
                        total > 0
                            ? `linear-gradient(
                        90deg,
                        rgb(var(--is-playing)) ${(isScheduled / total) * 100}%,
                        rgb(var(--on-field)) ${(isScheduled / total) * 100}%,
                        rgb(var(--on-field)) ${((isPlaying + isScheduled) / total) * 100}%,
                        rgba(var(--is-finished), 0.5) ${((isPlaying + isScheduled) / total) * 100}%
                    ) 1`
                            : "",
                }}
                tabIndex={0}
                onClick={onOpen}
            >
                <input
                    type="checkbox"
                    className={styles.fantasyMatchupTeamCheckbox}
                    checked={isSelected}
                    onClick={(ev) => {
                        ev.stopPropagation();
                        onSelect();
                    }}
                />
                <div className={styles.fantasyMatchupTeamName}>{team.name}</div>
                {!isOpen && (
                    <div className={styles.fantasyMatchupTeamPoints}>
                        {team.points.toFixed(2)}
                    </div>
                )}
            </div>
            <div
                className={clsx(styles.fantasyMatchupTeamPlayers, {
                    [styles.isOpen]: isOpen,
                })}
            >
                {team.players.map((player, i) => (
                    <FantasyPlayerComponent key={i} player={player} />
                ))}
            </div>
            {isOpen && (
                <div
                    className={clsx(
                        styles.fantasyMatchupTeamPoints,
                        styles.isEnd,
                    )}
                >
                    {team.points.toFixed(2)}
                </div>
            )}
        </div>
    );
}

export default FantasyMatchupTeam;
