import useAdTimeout from "./ad";
import { useFantasyStore, useMatchupPlayers } from "./fantasy";
import { useGamesStore } from "./games";
import { persistentSignal } from "./persistent";
import { GameStatus } from "./status";
import { Game, useStreamingGames } from "./streams";

export type CalculatedGame = {
    game?: Game;
    status: GameStatus;
    points: number;
    homePlayers: number;
    awayPlayers: number;
};

type BoostStore = Record<string, [number, number]>;

const boostStore = persistentSignal<BoostStore>("BOOST_STORE", {});

const setBoost = (id: string, boost: [number, number]) => {
    boostStore.value = {
        ...boostStore.value,
        [id]: boost,
    };
};

export const useBoost = (id: string) => {
    return [
        boostStore.value[id] ?? [0, 0],
        (boost: [number, number]) => setBoost(id, boost),
    ] as const;
};

export const usePointCalculator = () => {
    const players = useMatchupPlayers();
    const streamingGames = useStreamingGames();
    const adTimeout = useAdTimeout();
    const { selectedTeams } = useFantasyStore();
    const teamsCount = selectedTeams.length || 1;

    return streamingGames.map(({ status, game }): CalculatedGame => {
        const boost = boostStore.value[status.eventId];
        const homePlayers =
            players?.filter(
                (player) =>
                    (player.team === status.homeShort &&
                        player.position !== "DEF") ||
                    (player.team === status.awayShort &&
                        player.position === "DEF"),
            ).length ?? 0;
        const awayPlayers =
            players?.filter(
                (player) =>
                    (player.team === status.awayShort &&
                        player.position !== "DEF") ||
                    (player.team === status.homeShort &&
                        player.position === "DEF"),
            ).length ?? 0;

        // If the game is running, we might watch it
        let points = 1;

        // Add boost for every home and away boosts
        const [homeBoost, awayBoost] = boost ?? [0, 0];

        // Add points for every player who's on field
        if (status.ball === "home") {
            points += ((homePlayers + homeBoost) * 10) / teamsCount;
        }
        if (status.ball === "away") {
            points += ((awayPlayers + awayBoost) * 10) / teamsCount;
        }
        // If the teams are in the red zone it should be more interesting
        if (status.redzone) {
            points *= 10;
        }
        // If the teams are in the last 2 minutes it should be more interesting
        if (status.period === 2 && Number.parseInt(status.clock, 10) <= 2) {
            points *= 2;
        }
        if (status.period === 4 && Number.parseInt(status.clock, 10) <= 2) {
            points *= 4;
        }

        // If there hasn't been a new play since the ad timeout, the game is stopped
        if (status.lastPlayTimestamp < adTimeout) {
            points = 0;
        }

        return {
            status,
            game,
            points,
            homePlayers: homePlayers,
            awayPlayers: awayPlayers,
        };
    });
};

export const useActive = () => {
    const { isAuto, activeGame, playingGames } = useGamesStore();
    const gamesWithPoints = usePointCalculator();

    if (isAuto && gamesWithPoints !== undefined) {
        const playingGamesWithPoints = gamesWithPoints.filter(
            (status) =>
                status.game?.streamId &&
                playingGames.includes(status.game?.streamId),
        );

        const bestGame = playingGamesWithPoints.reduce(
            (currentBest, status) =>
                status.points > (currentBest?.points ?? 0)
                    ? status
                    : currentBest,
            undefined as CalculatedGame | undefined,
        );

        if (bestGame?.game) {
            return bestGame.game.streamId;
        }

        const mostBoostedGame = playingGamesWithPoints.reduce(
            (currentBest, status) => {
                const [currentHome, currentAway] = currentBest?.status
                    ? (boostStore.value[currentBest.status.eventId] ?? [0, 0])
                    : [0, 0];
                const [home, away] = boostStore.value[
                    status.status.eventId
                ] ?? [0, 0];

                return home + away > currentHome + currentAway
                    ? status
                    : currentBest;
            },
            undefined as CalculatedGame | undefined,
        );

        if (mostBoostedGame?.game) {
            return mostBoostedGame.game.streamId;
        }

        if (activeGame && playingGames.includes(activeGame)) {
            return activeGame;
        }

        return playingGamesWithPoints[0];
    }

    return activeGame;
};
