import { FunctionComponent as FC } from "preact";
import { useDelay } from "../data/delay";
import NumberInput from "./NumberInput";
import styles from "./Settings.module.css";

const Settings: FC = () => {
    const { delay, setDelay } = useDelay();

    return (
        <div className={styles.settings}>
            <span>Settings:</span>
            <div>
                delay{" "}
                <NumberInput
                    value={delay}
                    onChange={(d) => setDelay(Math.max(0, d))}
                />{" "}
                s
            </div>
        </div>
    );
};

export default Settings;
