import { persistentSignal } from "./persistent";

const sidebarStore = persistentSignal("SIDEBAR_STORE", true);

const setOpen = (open: boolean) => {
    sidebarStore.value = open;
};

export const useSidebarStore = () => {
    return {
        isOpen: sidebarStore.value,
        setOpen,
    };
};
