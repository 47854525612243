import { FunctionComponent as FC } from "preact";
import { useFantasyMatchupQuery, useFantasyStore } from "../data/fantasy";
import { useGamesStore } from "../data/games";
import FantasyMatchupTable from "./FantasyMatchupTable";
import styles from "./FantasyMatchups.module.css";

const FantasyMatchups: FC = () => {
    const { setAuto } = useGamesStore();
    const { lastSelectedTeamId, selectedTeams, selectTeam, openTeam } =
        useFantasyStore();

    const { data, isLoading } = useFantasyMatchupQuery();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        data && (
            <div className={styles.fantasyMatchupContainer}>
                <h2 className={styles.fantasyMatchupTitle}>Fantasy Matchup</h2>
                {data.map((matchup) => (
                    <FantasyMatchupTable
                        matchup={matchup}
                        selectedTeams={selectedTeams}
                        lastSelectedTeamId={lastSelectedTeamId}
                        onOpen={(id) => openTeam(id)}
                        onSelect={(id) => {
                            selectTeam(id);
                            setAuto(true);
                        }}
                    />
                ))}
            </div>
        )
    );
};

export default FantasyMatchups;
